import { useEffect, useState } from 'react';

import { RequestState } from './models';

const useGatsbyRefresh = () => {
  const [requestState, setRequestState] = useState<RequestState>(null);
  const [message, setMessage] = useState<string | null>(null);

  const handlerError = (msg: string) => {
    setRequestState('error');
    setMessage(msg);
  };

  const handleSuccess = (msg: string) => {
    setRequestState('success');
    setMessage(msg);
  };

  const handleReset = () => {
    setRequestState(null);
    setMessage(null);
  };

  const startRefresh = async () => {
    const requestOptions = {
      method: 'POST',
    };

    const { host, protocol } = window.location;
    const refreshEndpoint = `${protocol}//${host}/__refresh`;

    try {
      const response = await fetch(refreshEndpoint, requestOptions);

      if (response.status !== 200) {
        handlerError('Content refresh failed');

        return;
      }

      handleSuccess('Content refresh has started...');
    } catch (error) {
      handlerError('Content refresh failed');
    }
  };

  useEffect(() => {
    const FIVE_SECONDS_IN_MS = 5000;

    let timeout: ReturnType<typeof setTimeout>;

    if (requestState) {
      timeout = setTimeout(() => {
        handleReset();
      }, FIVE_SECONDS_IN_MS);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [requestState]);

  return {
    startRefresh,
    requestState,
    message,
  };
};

export default useGatsbyRefresh;
